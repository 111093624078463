import React, {useCallback, useEffect, useState} from 'react';
import {BadgeProps, Button, Grid, Paper, Select, SelectChangeEvent, TextField, Tooltip} from "@mui/material";
import Badge from "@mui/material/Badge";
import {FormattedMessage, useIntl} from "react-intl";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import Box from "@mui/material/Box";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import {Moment} from "moment";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import {styled} from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from '@mui/icons-material/Close';
import {InvoiceFilter} from "../../../../../corelogic/models/invoice";
import {SalesTypeDoc} from "../../../../secondary/InMemory/Data/enums";
import messages, {getMessageDescriptor} from "../../../../../i18n/messages";
import {DividingHR} from "../../Utils/DividingHR";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

const StyledBadge = styled(Badge)<BadgeProps>(({theme}) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}))

interface InvoiceFilterAccordionProps {
    onClickResetAllFilters?: () => void
    onClickApplyAllFilters?: (invoiceFilterState: InvoiceFilter, filterCount: number) => void
}

const InvoiceFilterAccordeon = (props: InvoiceFilterAccordionProps) => {
    const {onClickApplyAllFilters, onClickResetAllFilters} = props
    const intl = useIntl()
    const [filterCount, setFilterCount] = useState(0)
    const [billingDateValue, setBillingDateValue] = useState<Moment | null>(null)
    const [invoiceFilter, setInvoiceFilter] = useState<InvoiceFilter>({
        searchInput: "",
    })
    const [filterAccordionExpand, setFilterAccordionExpand] = useState({
        typesDoc: false
    })

    const mapFilterObjectToGetFilterCount = useCallback((filters: Object, keysToIgnore: string[] = []) => {
        let count = 0

        Object.entries(filters).forEach(([key, value]) => {
            if (keysToIgnore.includes(key)) {
                return
            }
            if (value === true || value === false) {
                count++
            } else if (Array.isArray(value)) {
                count += Array.from(value).length
            } else if (value) {
                count++
            }
        })
        return count
    }, [])

    useEffect(() => {
        setFilterCount(mapFilterObjectToGetFilterCount(invoiceFilter, []))
    }, [invoiceFilter, mapFilterObjectToGetFilterCount])

    const applyAllFilters = () => {
        if (onClickApplyAllFilters) {
            onClickApplyAllFilters(invoiceFilter, filterCount)
        }
    }

    const resetAllFilters = () => {
        if (onClickResetAllFilters) {
            onClickResetAllFilters()
        }
        setBillingDateValue(null)
        setInvoiceFilter({
            ...Object.fromEntries(
                Object.keys(invoiceFilter).map(key => [key, key === 'searchInput' ? invoiceFilter[key] : ""])
            )
        })
    }

    const handleToggleAccordionState = (name: string) => {
        setFilterAccordionExpand({
            ...filterAccordionExpand,
            [name]: !filterAccordionExpand[name as keyof Object]
        })
    }

    const handleChangeBillingDate = (newValue: Moment | null) => {
        if (newValue) {
            setBillingDateValue(newValue)
            setInvoiceFilter({...invoiceFilter, billingDate: newValue.toDate()})
        } else {
            setBillingDateValue(null)
            setInvoiceFilter({...invoiceFilter, billingDate: undefined})
        }
    }

    const handleChangeTextField = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInvoiceFilter({
            ...invoiceFilter,
            [event.target.name]: event.target.value.trim() === '' ? undefined : event.target.value
        })
    }

    const handleChangeSelect = (event: SelectChangeEvent) => {
        const {name, value} = event.target
        setInvoiceFilter({
            ...invoiceFilter,
            [name]: value
        })
    }

    const handleCleanField = (name: string) => {
        setInvoiceFilter({
            ...invoiceFilter,
            [name]: ""
        })
    }

    return (
        <Grid container direction="column" spacing={1} px={2} width={310}>
            <Grid item container justifyContent="space-evenly">
                <Grid item>
                    <StyledBadge badgeContent={filterCount} color="error">
                        <Tooltip title={intl.formatMessage(getMessageDescriptor("genericApplyFilters"))}>
                            <Button variant="contained" onClick={applyAllFilters}>
                                <FilterAltIcon/>
                            </Button>
                        </Tooltip>
                    </StyledBadge>
                </Grid>
                <Grid item>
                    <Tooltip title={intl.formatMessage(getMessageDescriptor("genericResetFilters"))}>
                        <Button variant="contained" onClick={resetAllFilters}>
                            <FilterAltOffIcon/>
                        </Button>
                    </Tooltip>
                </Grid>
            </Grid>
            <DividingHR subContent style={{marginBottom: 0}}/>
            <Grid item width={280}>
                <Paper elevation={5}>
                    <Grid container justifyContent="center" alignItems="center" p={1}>
                        <Grid item width="100%">
                            <Tooltip title="equals">
                                <TextField size="small" name="sodiwinNumInvoice" value={invoiceFilter.sodiwinNumInvoice}
                                           id="filled-basic"
                                           label={intl.formatMessage(getMessageDescriptor("invoiceFilterByNumInvoice"))}
                                           variant="outlined"
                                           fullWidth
                                           InputLabelProps={{shrink: true}}
                                           InputProps={{
                                               endAdornment: (
                                                   <InputAdornment position="end"
                                                                   onClick={() => handleCleanField("sodiwinNumInvoice")}
                                                                   sx={{cursor: "pointer"}}>
                                                       <CloseIcon/>
                                                   </InputAdornment>
                                               ),
                                           }}
                                           onChange={handleChangeTextField}/>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item width={280}>
                <Paper elevation={5}>
                    <Grid container alignItems="center" justifyContent="center" p={1}>
                        <Grid item>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Box sx={{display: "flex", alignItems: "center"}}>
                                    <DesktopDatePicker
                                        label={intl.formatMessage(getMessageDescriptor("invoiceFilterByBillingDate"))}
                                        inputFormat="DD/MM/YYYY"
                                        value={billingDateValue}
                                        onChange={handleChangeBillingDate}
                                        renderInput={(params) => <TextField fullWidth {...params}
                                                                            variant="outlined"
                                        />}
                                    />
                                </Box>
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item width={280}>
                <Paper elevation={5}>
                    <Grid container justifyContent="center" alignItems="center" p={1}>
                        <Grid item width="100%">
                            <Tooltip title="equals">
                                <TextField size="small" name="billedCustomerCode"
                                           value={invoiceFilter.billedCustomerCode}
                                           id="filled-basic"
                                           label={intl.formatMessage(getMessageDescriptor("invoiceFilterByBilledCustomerCode"))}
                                           variant="outlined"
                                           fullWidth
                                           InputLabelProps={{shrink: true}}
                                           InputProps={{
                                               endAdornment: (
                                                   <InputAdornment position="end"
                                                                   onClick={() => handleCleanField("billedCustomerCode")}
                                                                   sx={{cursor: "pointer"}}>
                                                       <CloseIcon/>
                                                   </InputAdornment>
                                               ),
                                           }}
                                           onChange={handleChangeTextField}/>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item width={280}>
                <Paper elevation={5}>
                    <Grid container justifyContent="center" alignItems="center" p={1}>
                        <Grid item width="100%">
                            <FormControl fullWidth>
                                <InputLabel id="invoice-filter-select-label">
                                    <FormattedMessage id={messages.invoiceFilterByOriginalSalesDocumentType.id}/>
                                </InputLabel>
                                <Select
                                    value={invoiceFilter.originalSalesDocumentType || ""}
                                    name="originalSalesDocumentType"
                                    onChange={handleChangeSelect}
                                    label={intl.formatMessage(getMessageDescriptor("invoiceFilterByOriginalSalesDocumentType"))}
                                    fullWidth
                                >
                                    <MenuItem value="">
                                        &nbsp;
                                    </MenuItem>
                                    {Object.entries(SalesTypeDoc).map(([key, value]) => (
                                        <MenuItem key={key} value={key}>
                                            {value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item width={280}>
                <Paper elevation={5}>
                    <Grid container justifyContent="center" alignItems="center" p={1}>
                        <Grid item width="100%">
                            <Tooltip title="equals">
                                <TextField size="small" name="originalSalesDocumentNum"
                                           value={invoiceFilter.originalSalesDocumentNum}
                                           id="filled-basic"
                                           label={intl.formatMessage(getMessageDescriptor("invoiceFilterByOriginalSalesDocumentNum"))}
                                           variant="outlined"
                                           fullWidth
                                           InputLabelProps={{shrink: true}}
                                           InputProps={{
                                               endAdornment: (
                                                   <InputAdornment position="end"
                                                                   onClick={() => handleCleanField("originalSalesDocumentNum")}
                                                                   sx={{cursor: "pointer"}}>
                                                       <CloseIcon/>
                                                   </InputAdornment>
                                               ),
                                           }}
                                           onChange={handleChangeTextField}/>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item width={280}>
                <Paper elevation={5}>
                    <Grid container justifyContent="center" alignItems="center" p={1}>
                        <Grid item width="100%">
                            <Tooltip title="equals">
                                <TextField size="small" name="itemCode" value={invoiceFilter.itemCode}
                                           id="filled-basic"
                                           label={intl.formatMessage(getMessageDescriptor("invoiceFilterByItemCodeInInvoiceLine"))}
                                           variant="outlined"
                                           fullWidth
                                           InputProps={{
                                               endAdornment: (
                                                   <InputAdornment position="end"
                                                                   onClick={() => handleCleanField("itemCode")}
                                                                   sx={{cursor: "pointer"}}>
                                                       <CloseIcon/>
                                                   </InputAdornment>
                                               ),
                                           }}
                                           InputLabelProps={{shrink: true}}
                                           onChange={handleChangeTextField}/>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default InvoiceFilterAccordeon